import React from "react";

// styles
import * as styles from "./AskDoctor.module.css";

const AskDoctor = () => {
  return (
    <div className="mt-4">
      <div className={`container twinContainer`}>
        <div className="row">
          {/* Desktop Line */}
          <div className={`col-12 ${styles.askDoc_dsktpLine_1}`}></div>
          {/* Heading */}
          <div
            className={`col-12 col-sm-12 col-md-6 col-lg-6 ${styles.askDoc_leftSec}`}
          >
            <div className={styles.askDoc_leftSec_content}>
              <h3>Ask the doctor</h3>
              <p>Here’s what you can expect from your appointment with the Doctor</p>
            </div>
            {/* Mobile Line */}
            <div className={`col-12 ${styles.askDoc_mbleLine_1}`}></div>
          </div>
          {/* content */}
          <div
            className={`col-12 col-sm-12 col-md-6 col-lg-6 ${styles.askDoc_rightSec}`}
          >
            {/* First Content */}
            <div
              className={`d-flex align-items-center ${styles.askDoc_rightsec_firstCnt}`}
            >
              {/* serial */}
              <div className={styles.askDoc_rightsec_firstCnt_srl}>
                <p>01</p>
              </div>
              {/* icon */}
              <div className={styles.askDoc_rightsec_firstCnt_icn}>
                <span className={`icon-01`} aria-hidden="true"></span>
              </div>
              {/* text */}
              <div className={styles.askDoc_rightsec_firstCnt_txt}>
                <p>
                  A personalised video consultation with the doctor of your
                  choice
                </p>
              </div>
            </div>
            {/* Second Content */}
            <div
              className={`d-flex align-items-center ${styles.askDoc_rightsec_firstCnt}`}
            >
              {/* serial */}
              <div className={styles.askDoc_rightsec_firstCnt_srl}>
                <p>02</p>
              </div>
              {/* icon */}
              <div className={styles.askDoc_rightsec_firstCnt_icn}>
                <span className={`icon-02`} aria-hidden="true"></span>
              </div>
              {/* text */}
              <div className={styles.askDoc_rightsec_firstCnt_txt}>
                <p>
                  20-minute online consultation from the comfort of your home
                </p>
              </div>
            </div>
            {/* Third Content */}
            {/* <div
              className={`d-flex align-items-center ${styles.askDoc_rightsec_thirdCnt}`}
            >
              <div className={styles.askDoc_rightsec_firstCnt_srl}>
                <p>03</p>
              </div>
              <div className={styles.askDoc_rightsec_firstCnt_icn}>
                <span className={`icon-03`} aria-hidden="true"></span>
              </div>
              <div className={styles.askDoc_rightsec_firstCnt_txt}>
                <p>₹2,000 worth of exclusive offers from the Twin program</p>
              </div>
            </div> */}
            {/* Mobile Line */}
            <div className={`col-12 ${styles.askDoc_dsktpLine_2}`}></div>
          </div>
          {/* Mobile Line */}
          <div className={`col-12 ${styles.askDoc_dsktpLine_3}`}></div>
        </div>
      </div>
    </div>
  );
};

export default AskDoctor;
