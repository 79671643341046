import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// components
import PatientStories from "./PatientStories";

//styles
import * as styles from "./SuccessStories.module.css";

const stories = [
  {
    userImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user1.png"
        alt=""
        placeholder="blurred"
      />
    ),
    heading: "Srivathsan Rangarajan reversed diabetes in 6 months",
    rating: Number(5),
    location: "37 years old, Bangaluru",
    description:
      "I have been looking for an alternate treatment for Diabetes and have been under medication for almost 8.5 years. Twin program gave me the confidence that i can continue without medication and control the blood sugar levels purely with diet and lifestyle change.",
    readMoreLink: "testimonial-details/?id=3",
    months: "6",
    a1cStart: "6.7",
    a1cEnd: "6.1",
    medicineStart: "2",
    medicineEnd: "0",
    reportImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user1-report.png"
        alt=""
        placeholder="blurred"
      />
    ),
  },
  {
    userImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user2.png"
        alt=""
        placeholder="blurred"
      />
    ),
    heading: "Dinakar Shetty reversed diabetes in 8 months",
    rating: Number(5),
    location: "64 years old, Bangaluru",
    description:
      "Twin program promises Health & Happiness. This I have experienced by enrolling into d Twin program in last May 2020. I was devastated with the diabetic reading of 726 though I was new to this disease. Immediately my Doctor put me on insulin.",
    readMoreLink: "testimonial-details/?id=1",
    months: "8",
    a1cStart: "12.7",
    a1cEnd: "5.7",
    medicineStart: "4",
    medicineEnd: "0",
    reportImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user2-report.png"
        alt=""
        placeholder="blurred"
      />
    ),
  },
  {
    userImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user3.png"
        alt=""
        placeholder="blurred"
      />
    ),
    heading: "Nandani Singh reversed diabetes in 7 months",
    rating: Number(5),
    location: "61 years old, Delhi",
    description:
      "It was a life altering experience.I dropped 15kgs of excess weight and HB a1c from 7.3 to 6.1 in 3 months!! This is unbelievable and had I not gone through this program, I would have thought it impossible to repair and heal myself to good health.",
    readMoreLink: "testimonial-details/?id=2",
    months: "7",
    a1cStart: "7.3",
    a1cEnd: "6.1",
    medicineStart: "1",
    medicineEnd: "0",
    reportImage: (
      <StaticImage
        src="../../../images/home/customer-stories/user3-report.png"
        alt=""
        placeholder="blurred"
      />
    ),
  },
];

const Carousel = () => {
  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-touch="true"
      >
        <div className="row">
          <div className="d-flex order-last order-md-2 order-lg-2 justify-content-start justify-content-md-end justify-content-lg-end d-md-flex d-lg-flex col-md-6 col-lg-6">
            <div
              className={`carousel-indicators ${styles.caraIndicator} ${styles.caraIndicatorTop}`}
            >
              <li
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="threeDot doctorDot active"
                aria-current="true"
                aria-label="Slide 1"
              ></li>
              <li
                data-bs-target="#carouselExampleIndicators"
                className="threeDot doctorDot"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></li>
              <li
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                className="threeDot doctorDot"
                aria-label="Slide 3"
              ></li>
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 order-2">
            <div className="pt-5 carousel-inner">
              <div className="carousel-item active">
                <PatientStories {...stories[0]} />
              </div>
              <div className="carousel-item">
                <PatientStories {...stories[1]} />
              </div>
              <div className="carousel-item">
                <PatientStories {...stories[2]} />
              </div>
            </div>
          </div>
        </div>
        <button
          className={`carousel-control-prev d-none justify-content-start d-lg-inline-flex`}
          type="button"
          style={{ cursor: "auto", justifyContent: "start", width: "auto" }}
        >
          <span
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
            className={`carousel-control-prev-icon ${styles.previousScreenArrow}`}
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>

        <button
          type="button"
          className={`carousel-control-next d-none d-lg-flex`}
          style={{ cursor: "auto", justifyContent: "end", width: "auto" }}
        >
          <span
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
            className={`ms-5 cursorPointer carousel-control-next-icon ${styles.nextScreenArrow}`}
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default Carousel;
