// extracted by mini-css-extract-plugin
export var askDoc_dsktpLine_1 = "AskDoctor-module--askDoc_dsktpLine_1--U5yzN";
export var askDoc_dsktpLine_2 = "AskDoctor-module--askDoc_dsktpLine_2--6NEyN";
export var askDoc_dsktpLine_3 = "AskDoctor-module--askDoc_dsktpLine_3--Mdpt3";
export var askDoc_leftSec = "AskDoctor-module--askDoc_leftSec--04TB7";
export var askDoc_leftSec_content = "AskDoctor-module--askDoc_leftSec_content--jF+mM";
export var askDoc_mbleLine_1 = "AskDoctor-module--askDoc_mbleLine_1--QxdOb";
export var askDoc_mbleLine_2 = "AskDoctor-module--askDoc_mbleLine_2--dt+8B";
export var askDoc_rightSec = "AskDoctor-module--askDoc_rightSec--uGj01";
export var askDoc_rightsec_firstCnt = "AskDoctor-module--askDoc_rightsec_firstCnt--F2RRU";
export var askDoc_rightsec_firstCnt_icn = "AskDoctor-module--askDoc_rightsec_firstCnt_icn--3tyKl";
export var askDoc_rightsec_firstCnt_srl = "AskDoctor-module--askDoc_rightsec_firstCnt_srl--FEaZn";
export var askDoc_rightsec_firstCnt_txt = "AskDoctor-module--askDoc_rightsec_firstCnt_txt--iWE00";
export var askDoc_rightsec_thirdCnt = "AskDoctor-module--askDoc_rightsec_thirdCnt--+WKif";