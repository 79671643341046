import { useStaticQuery, graphql } from "gatsby";

export const useDoctorDetailQuery = (component = "doctor") => {
  const { allTwinPage } = useStaticQuery(
    graphql`
      query DOCTOR_DETAIL_QUERY {
        allTwinPage(filter: { page: { eq: "doctor" } }) {
          edges {
            node {
              page
              component
              data {
                timer
                productId
              }
            }
          }
        }
      }
    `
  );
  // console.log("all node", allTwinPage.edges);
  const findComponent = allTwinPage.edges.find(
    item => item.node.component === component
  );
  // console.log("findComponent", findComponent);
  let node = findComponent.node.data;
  // console.log(node);

  return node;
};
