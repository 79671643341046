import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

//components
import RegisterForm from "./RegisterForm";

// css
import * as styles from "./RegisterForm.module.css";

const RegisterFormModal = props => {
  return (
    <>
      {props.windowSize && props.windowSize.width <= 768 ? (
        <Offcanvas
          show={props.showCanvasModal}
          placement="bottom"
          style={{ height: "86%", borderRadius: "24px" }}
        >
          {/* Mobile Line */}
          <div className={styles.regForm_mobLine}></div>
          <Offcanvas.Header className={styles.registerForm_modalMob_header}>
            <div
              onClick={props.onHideCanvasModal}
              className={styles.registerForm_modalMob_crossIcn}
              role="presentation"
            >
              <span className="icon-cross" aria-hidden="true"></span>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ background: "#FFFFFF" }}>
            <RegisterForm
              doctorId={props.doctorId}
              productId={props.productId}
              appHeader={props.appHeader}
              clientInfo={props.clientInfo}
              twinApp={props.twinApp}
              clientId={props.clientId}
            />
          </Offcanvas.Body>
        </Offcanvas>
      ) : (
        <Offcanvas show={props.showCanvasModal} placement="end">
          <Offcanvas.Header>
            <div
              onClick={props.onHideCanvasModal}
              className={styles.registerForm_modalDesk_crossIcn}
              role="presentation"
            >
              <span className="icon-cross" aria-hidden="true"></span>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ background: "#FFFFFF" }}>
            <RegisterForm
              doctorId={props.doctorId}
              productId={props.productId}
              appHeader={props.appHeader}
              clientInfo={props.clientInfo}
              twinApp={props.twinApp}
              clientId={props.clientId}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default RegisterFormModal;
