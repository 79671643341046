// extracted by mini-css-extract-plugin
export var docList_bookCta = "DoctorList-module--docList_bookCta--K4JO8";
export var docList_detailsSec = "DoctorList-module--docList_detailsSec--iwwKu";
export var docList_docDsigntn = "DoctorList-module--docList_docDsigntn--Z1Z9B";
export var docList_docExp = "DoctorList-module--docList_docExp--9B1H3";
export var docList_docImg = "DoctorList-module--docList_docImg--muVja";
export var docList_docLan = "DoctorList-module--docList_docLan--74q0u";
export var docList_docTitle = "DoctorList-module--docList_docTitle--V+7Bh";
export var docList_dsktpLine_1 = "DoctorList-module--docList_dsktpLine_1--v9DXe";
export var docList_dsktpLine_2 = "DoctorList-module--docList_dsktpLine_2--35+i2";
export var docList_hdng = "DoctorList-module--docList_hdng--2ZLIR";
export var docList_lastSec = "DoctorList-module--docList_lastSec--6a-HG";
export var docList_rightSec = "DoctorList-module--docList_rightSec--tAZtL";
export var docList_sec = "DoctorList-module--docList_sec--1ArOC";
export var docList_singleDocList = "DoctorList-module--docList_singleDocList--bW8-Q";
export var docList_verifySec = "DoctorList-module--docList_verifySec--AJLct";