import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// Components
import Timer from "../../Timer/Timer";

// styles
import * as styles from "./Banner.module.css";

const Banner = props => {
  return (
    <>
      <div className={styles.bnnr_sec}>
        <div className={`container twinContainer`}>
          <div className="row">
            {/* Banner Content */}
            <div
              className={`col-12 col-sm-12 col-md-6 col-lg-6 ${styles.bnnr_contentSec}`}
            >
              {/* Heading */}
              <div className={styles.bnnr_hdng}>
                <h3>
                  Skip the Queue! <br></br>book an appointment with the best
                  doctors in India
                </h3>
              </div>
              {/* Timer component*/}
              <div className={styles.bnnr_timerSec}>
                <Timer text={"to enjoy benefits worth Rs. 1000"} time={300} />
              </div>
              {/* CTA for desktop */}
              <div className={styles.bnnr_dsktpCtaSec}>
                <AnchorLink
                  to={`/doctor-consult${
                    props.twinApp && props.twinApp === "yes"
                      ? "&twinApp=yes"
                      : ""
                  }#doctors-list`}
                >
                  <div className={styles.bnnr_dsktpCtaSec_cta}>
                    <button>
                      <div>
                        <p>Book an appointment</p>
                        <span
                          className={`icon-arrow-forward`}
                          aria-hidden="true"
                          style={{ color: "#29817A" }}
                        ></span>
                      </div>
                    </button>
                  </div>
                </AnchorLink>
                {/* Gurantee Txt for desktop */}
                <div className="d-flex mt-4 ms-3 align-items-center">
                  {/* tick icon */}
                  <div className={styles.bnnr_guranteSec}>
                    <div className={styles.bnnr_guranteSec_strImg}></div>
                    <div className={styles.bnnr_guranteSec_tickIcn}>
                      <span className={`icon-07`} aria-hidden="true"></span>
                    </div>
                  </div>
                  {/* text */}
                  <div className={styles.bnnr_guranteSec_txt}>
                    <p>100% MONEY BACK GUARANTEE</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Banner Image */}
            <div
              className={`col-12 col-sm-12 col-md-6 col-lg-6 ${styles.bnnr_imageSec}`}
            >
              <div className={styles.bnnr_imgSec}>
                <StaticImage
                  src="../../../images/doctor/doctor-banner-mob.png"
                  alt=""
                  className="img-fluid"
                  placeholder="blurred"
                  quality={90}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
