import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// component
import Star from "../../Star/Star";

//styles
import * as styles from "./SuccessStories.module.css";

const PatientStories = props => {
  return (
    <div className="row justify-content-center pb-2">
      <div className="col-12 col-md-5 col-lg-5">
        <div className="row">
          <div className="col-4 d-flex justify-content-end justify-content-md-start justify-content-lg-start  order-md-first order-lg-frist order-last col-lg-12 col-lg-12 pb-5">
            <div className={styles.docImg1}>{props.userImage}</div>
          </div>

          <div className="col-8 order-first order-md-last order-lg-last col-lg-8 col-lg-8 pt-2 pt-md-0 pt-lg-0">
            <div className="col-12">
              <span className={styles.cusText1}>{props.heading}</span>
            </div>
            <div className="col-12 col-lg-12 col-lg-12">
              <div>
                <p className={styles.cusText2}>{props.location}</p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="col-11">
                <div className="d-flex align-items-center">
                  <span
                    className={`icon-07`}
                    aria-hidden="true"
                    style={{ color: "#4095F7", fontSize: "23px" }}
                  ></span>
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#646464",
                      paddingLeft: "4px",
                    }}
                  >
                    Facebook Verified
                  </p>
                </div>
              </div>
              <div
                className={`col-12 col-lg-12 col-lg-12 ${styles.carosule_starSec}`}
              >
                <Star
                  stars={5}
                  ratingValue={props.rating}
                  fillColor="#f1a545"
                  size={20}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {/* <div className="col-12 d-md-flex d-lg-flex col-lg-11 col-lg-11 pt-5 pb-3">
            <div>
              <p className={`${styles.cusText4}`}>{props.heading}</p>
            </div>
          </div> */}
          <div className="col-12 d-block d-md-flex d-lg-flex col-lg-11 col-lg-11 mt-4">
            <div>
              <p className={`${styles.cusText3}`}>{props.description}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`col-12 col-md-5 col-lg-5 pt-3 ${styles.bottomContect}`}>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="row">
              <div className="col-6 col-md-6 col-lg-6">
                <p className={styles.custText33}>Start of the program</p>
              </div>
              <div className="col-6 col-md-6 col-lg-6 d-flex justify-content-end">
                <span className={styles.custText33}>
                  within {props.months} months
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="row align-items-center">
              <div className="d-flex">
                <div className="pe-3 mt-2">
                  <span className="smallIcon icon-drop" />
                </div>
                <div className="d-flex mt-2">
                  <p className={styles.progText1}>A1C: </p>
                  <p className={`${styles.progText2} ps-1`}>{props.a1cStart}</p>
                </div>
                <div className="ps-2 pt-1 pb-2 mt-2 pe-2">
                  <StaticImage
                    src="../../../images/home/bigArrow.svg"
                    alt=""
                    placeholder="blurred"
                  />
                </div>
                <div
                  className={`rounded-pill d-flex align-items-center justify-content-center ${styles.greenPillBox} ms-2`}
                >
                  <span>{props.a1cEnd}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="row align-items-center">
              <div className="d-flex">
                <div className="pe-3 mt-2">
                  <span className="smallIcon icon-tablet" />
                </div>
                <div className="d-flex mt-2">
                  <p className={styles.progText1}>Medicines: </p>
                  <p className={`${styles.progText2} ps-1`}>
                    {props.medicineStart}
                  </p>
                </div>
                <div className="ps-2 pt-1 mt-2 pe-2">
                  <StaticImage
                    src="../../../images/home/bigSmallArrow.svg"
                    alt=""
                    placeholder="blurred"
                  />
                </div>
                <div
                  className={`rounded-pill d-flex align-items-center justify-content-center ${styles.greenPillBox} ms-2`}
                >
                  <span>{props.medicineEnd}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-4 justify-content-center">
          <div className="col-12 col-md-12 col-lg-12">
            <div className="row ps-4">
              <div className="col-12 col-md-12 col-lg-12">
                <div className={styles.docGraphImg}>{props.reportImage}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientStories;
