import React from "react";
import { useTimer } from "react-timer-hook";
import PropTypes from "prop-types";

// styles
import * as styles from "./Timer.module.css";

const propTypes = {
  text: PropTypes.string,
  time: PropTypes.number,
};

const defaultProps = {
  text: "To avail benefits",
  time: 300,
};

const Timer = props => {
  const getTime = new Date();
  getTime.setSeconds(getTime.getSeconds() + Number(props.time));

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: getTime,
    autoStart: true,
    onExpire: () => {
      const time = new Date();
      time.setSeconds(time.getSeconds() + Number(props.time));
      setTimeout(() => restart(time), Number(props.time));
    },
  });

  return (
    <div>
      <div className="d-flex align-items-center">
        <div className={styles.timer_minSec}>{minutes} min</div>
        <div> : </div>

        <div className={styles.timer_secndsSec}>
          {seconds} sec {""}
        </div>
        <div className={styles.timer_txtSec}>
          <p>{props.text}</p>
        </div>
      </div>
    </div>
  );
};

Timer.propTypes = propTypes;
Timer.defaultProps = defaultProps;

export default Timer;

// How To Use
// Ex1: <Timer />
// Ex2: <Timer text={"to avail benefits worth Rs. 1000"} time={300} />
// time={300{60 * 5} means 5 minutes}
