import React, { useState, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";

// seo
import Seo from "../components/Seo";

// app header
import AppHeader from "../components/AppHeader/AppHeader";
// import AppHeaderTwinApp from "../components/AppHeader/AppHeaderTwinApp";

// config
import config from "../config";

// hook
import { useDoctorDetailQuery } from "../hooks/useDoctorDetailQuery";
import { useWindowSize } from "../hooks/useWindowSize";

// components
import AskDoctor from "../components/DoctorConsult/AskDoctor/AskDoctor";
import Banner from "../components/DoctorConsult/Banner/Banner";
import DoctorList from "../components/DoctorConsult/DoctorList/DoctorList";
import FloatingCta from "../components/DoctorConsult/FloatingCta/FloatingCta";
import SuccessStories from "../components/DoctorConsult/SuccessStories/SuccessStories";

const DoctorConsultPage = props => {
  const [clientInfo, setClientInfo] = useState({});
  const [doctorList, setDoctorList] = useState({});
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [coupanEnabled, setCoupanEnabled] = useState(false);
  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);

  const windowSize = useWindowSize();
  const doctorNode = useDoctorDetailQuery();

  const productId = doctorNode[0].productId;

  const { appHeader, clientId, twinApp } = queryString.parse(
    props.location.search
  );

  // -------> GET COUPON DETAILS <--------
  useEffect(() => {
    const getListOfCoupons = async () => {
      const couponOptions = {
        method: "get",
        url:
          config.DX_API_BASE_URL +
          `/api/marketing/coupons?size=3&pId=${productId}&type=doctor`,
      };
      try {
        setLoading(true);
        const response = await axios(couponOptions);
        let resultData = response.data.results.filter(obj => {
          return obj.priority === 1;
        });
        if (resultData.length !== 0) {
          setDiscountedPrice(resultData[0].discountedAmount);
          setCoupanEnabled(true);
          setLoading(false);
        } else {
          setLoading(true);
          setCoupanEnabled(false);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error in fetching doctors details", error);
        // setServerError("Something went wrong while fetching coupon details");
      }
    };
    getListOfCoupons();
  }, [productId]);

  // -------> GET LIST OF DOCTORS <-------
  useEffect(() => {
    const getDoctorList = async () => {
      const doctorOptions = {
        method: "get",
        url:
          config.DX_API_BASE_URL +
          "/api/doctor?pageSize=100&page=1&filter[doctorConsultEnabled]=1&filter[enabled]=1",
      };
      try {
        setLoading(true);
        const response = await axios(doctorOptions);
        setDoctorList(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error in fetching doctors list", error);
        setLoading(false);
        setServerError("Something went wrong...Please refersh the page.");
      }
    };
    getDoctorList();
  }, []);

  // ---------> GET CLIENT DETAILS <---------
  useEffect(() => {
    const getClientDetails = async clientId => {
      const options = {
        method: "get",
        url: config.DX_API_BASE_URL + "/api/clients/details/" + clientId,
      };

      try {
        const response = await axios(options);
        setClientInfo(response.data.results[0]);
      } catch (error) {
        console.error("client api server error", error);
      }
    };

    if (clientId) {
      getClientDetails(clientId);
    }
  }, [clientId]);

  return (
    <>
      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />

      {/* Start ---> Header for Twin App */}
      {/* {appHeader === "no" && twinApp === "yes" && (
        <AppHeaderTwinApp text="" isBack={true} backUrl="mobile_back" />
      )} */}

      {appHeader === "mobile" && twinApp === "yes" && (
        <AppHeader text="" isBack={true} backUrl="mobile_back" />
      )}
      {/* End ---> Header for Twin App */}

      <div>
        <FloatingCta twinApp={twinApp} appHeader={appHeader} />
      </div>
      <Banner twinApp={twinApp} />
      <AskDoctor />
      <SuccessStories bgColor={"bgLightGreen"} titleText="Success stories" />
      <div id="doctors-list"></div>
      <DoctorList
        windowSize={windowSize}
        doctorList={doctorList}
        discountedPrice={discountedPrice}
        coupanEnabled={coupanEnabled}
        productId={productId}
        loading={loading}
        appHeader={appHeader}
        clientInfo={clientInfo}
        twinApp={twinApp}
        clientId={clientId}
      />
      <div className="mt-3 text-center text-danger">{serverError} &nbsp;</div>
    </>
  );
};

export default DoctorConsultPage;
