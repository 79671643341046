// extracted by mini-css-extract-plugin
export var bnnr_contentSec = "Banner-module--bnnr_contentSec--gBRM2";
export var bnnr_dsktpCtaSec = "Banner-module--bnnr_dsktpCtaSec--o48zl";
export var bnnr_dsktpCtaSec_cta = "Banner-module--bnnr_dsktpCtaSec_cta--Jfrx2";
export var bnnr_guranteSec = "Banner-module--bnnr_guranteSec--bJBsd";
export var bnnr_guranteSec_strImg = "Banner-module--bnnr_guranteSec_strImg--IX140";
export var bnnr_guranteSec_tickIcn = "Banner-module--bnnr_guranteSec_tickIcn--cguo2";
export var bnnr_guranteSec_txt = "Banner-module--bnnr_guranteSec_txt--A3dRb";
export var bnnr_hdng = "Banner-module--bnnr_hdng--KOvUQ";
export var bnnr_imageSec = "Banner-module--bnnr_imageSec--Neoci";
export var bnnr_imgSec = "Banner-module--bnnr_imgSec--0UJD7";
export var bnnr_sec = "Banner-module--bnnr_sec--NTOOc";
export var bnnr_timerSec = "Banner-module--bnnr_timerSec--o8QLd";