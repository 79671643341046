// extracted by mini-css-extract-plugin
export var bottomContect = "SuccessStories-module--bottomContect--halfm";
export var boxContainer = "SuccessStories-module--boxContainer--l-As8";
export var caraIndicator = "SuccessStories-module--caraIndicator--l20ew";
export var caraIndicatorTop = "SuccessStories-module--caraIndicatorTop--8iRzs";
export var carosule_starSec = "SuccessStories-module--carosule_starSec--kEhVb";
export var collapseLink = "SuccessStories-module--collapseLink--BvMUL";
export var cusText1 = "SuccessStories-module--cusText1--RT7Cx";
export var cusText2 = "SuccessStories-module--cusText2--fBXIF";
export var cusText3 = "SuccessStories-module--cusText3--DbXa+";
export var cusText4 = "SuccessStories-module--cusText4--YEOXc";
export var custText33 = "SuccessStories-module--custText33--J1-XM";
export var darkHr = "SuccessStories-module--darkHr--IVk5n";
export var dateText1 = "SuccessStories-module--dateText1--k6OBe";
export var docGraphImg = "SuccessStories-module--docGraphImg--tU1pH";
export var docImg1 = "SuccessStories-module--docImg1--wGPQw";
export var drImg = "SuccessStories-module--drImg--8RjXF";
export var faceText = "SuccessStories-module--faceText--autKW";
export var greenPillBox = "SuccessStories-module--greenPillBox--xhb2U";
export var marginCo = "SuccessStories-module--marginCo--hxa4m";
export var nextScreenArrow = "SuccessStories-module--nextScreenArrow--m1NlI";
export var nosText = "SuccessStories-module--nosText--NE7--";
export var previousScreenArrow = "SuccessStories-module--previousScreenArrow--JaZBj";
export var progText1 = "SuccessStories-module--progText1--I-pBj";
export var progText2 = "SuccessStories-module--progText2--dxQrQ";
export var resContent2 = "SuccessStories-module--resContent2--MbJC6";
export var resTitle1 = "SuccessStories-module--resTitle1--x1TUT";
export var resTitle11 = "SuccessStories-module--resTitle11--dqKpB";
export var secondaryTxt = "SuccessStories-module--secondaryTxt--VNHo+";