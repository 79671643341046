import React, { useState } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Loader from "react-loader-spinner";
import { StaticImage } from "gatsby-plugin-image";

// Sticky Cta
import StickyCtaHook from "./StickyCtaHook";

// styles
import * as styles from "./FloatingCta.module.css";

const FloatingCta = props => {
  const [showCta, setShowCta] = useState("block");

  const { isSticky } = StickyCtaHook();

  const handleCtaProperty = () => {
    setShowCta("none");
  };

  return (
    <div className={styles.floatCta_sec}>
      {props.paymentAmount ? (
        <div className={`container`}>
          <div className="row mt-3">
            <div
              className={`col-12 d-flex align-items-center justify-content-center`}
            >
              <div>
                <div>
                  <StaticImage
                    src="../../../images/appDx/doc_con_tick.png"
                    alt=""
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
              </div>
              <div className={styles.floatCta_topSec_txtSec}>
                <p>100% MONEY BACK GUARANTEE</p>
              </div>
            </div>
            {props.coupanEnabled ? (
              <div className={`col-12 ${styles.floatCta_bottomSec}`}>
                <button onClick={props.handleBookDoctorAppointment}>
                  <div className="position-relative">
                    <span>Book now @</span>
                    <span className={styles.floatCta_bottomSec_actPrce}>
                      ₹{props.costPrice}
                    </span>{" "}
                    <span>{props.sellingPrice}</span>
                    <span
                      className={`icon-arrow-forward ${styles.floatCta_bottomSec_arrwIcn}`}
                      aria-hidden="true"
                    ></span>
                    <span
                      className="position-absolute"
                      style={{ left: "50%", top: "-3px" }}
                    >
                      <Loader
                        type="ThreeDots"
                        color="#29817a"
                        height={30}
                        width={30}
                        visible={props.payLoading}
                      />
                    </span>
                  </div>
                </button>
              </div>
            ) : (
              <div className={`col-12 ${styles.floatCta_bottomSec}`}>
                <button onClick={props.handleBookDoctorAppointment}>
                  <div className="position-relative">
                    <span>Book now @</span>
                    <span
                      className={styles.floatCta_bottomSec_actPrce_cpnDisable}
                    >
                      ₹{props.costPrice}
                    </span>
                    <span
                      className={`icon-arrow-forward ${styles.floatCta_bottomSec_arrwIcn}`}
                      aria-hidden="true"
                    ></span>
                    <span
                      className="position-absolute"
                      style={{ left: "50%", top: "-3px" }}
                    >
                      <Loader
                        type="ThreeDots"
                        color="#29817a"
                        height={30}
                        width={30}
                        visible={props.payLoading}
                      />
                    </span>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          {isSticky && (
            <div className={`container`} style={{ display: showCta }}>
              <div className="row mt-3">
                <div
                  className={`col-12 d-flex align-items-center justify-content-center`}
                >
                  <div>
                    <div>
                      <StaticImage
                        src="../../../images/appDx/doc_con_tick.png"
                        alt=""
                        className="img-fluid"
                        placeholder="blurred"
                        quality={90}
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                  </div>
                  <div className={styles.floatCta_topSec_txtSec}>
                    <p>100% MONEY BACK GUARANTEE</p>
                  </div>
                </div>
                {props.appHeader === "mobile" ? (
                  <AnchorLink
                    to="/doctor-consult/#doctors-list"
                    className={styles.floatCta_anchorLink}
                  >
                    <div className={`col-12 ${styles.floatCta_bottomSec}`}>
                      <button onClick={handleCtaProperty}>
                        <span>Book appointment </span>
                        <span
                          className={`icon-arrow-forward ${styles.floatCta_bottomSec_arrwIcn}`}
                          aria-hidden="true"
                        ></span>
                      </button>
                    </div>
                  </AnchorLink>
                ) : props.appHeader === "no" && props.twinApp === "yes" ? (
                  <AnchorLink
                    to="/doctor-consult/#doctors-list"
                    className={styles.floatCta_anchorLink}
                    stripHash="true"
                  >
                    <div className={`col-12 ${styles.floatCta_bottomSec}`}>
                      <button onClick={handleCtaProperty}>
                        <span>Book appointment </span>
                        <span
                          className={`icon-arrow-forward ${styles.floatCta_bottomSec_arrwIcn}`}
                          aria-hidden="true"
                        ></span>
                      </button>
                    </div>
                  </AnchorLink>
                ) : (
                  <AnchorLink
                    to="/doctor-consult/#doctors-list"
                    className={styles.floatCta_anchorLink}
                  >
                    <div className={`col-12 ${styles.floatCta_bottomSec}`}>
                      <button onClick={handleCtaProperty}>
                        <span>Book appointment </span>
                        <span
                          className={`icon-arrow-forward ${styles.floatCta_bottomSec_arrwIcn}`}
                          aria-hidden="true"
                        ></span>
                      </button>
                    </div>
                  </AnchorLink>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FloatingCta;
