import React from "react";
import PropTypes from "prop-types";
import { RatingView } from "react-simple-star-rating";

const propTypes = {
  stars: PropTypes.number,
  ratingValue: PropTypes.number,
  fillColor: PropTypes.string,
  size: PropTypes.number,
};

const defaultProps = {
  stars: 5,
  ratingValue: 0,
  fillColor: "#f1a545",
  size: 20,
};

const Star = props => {
  return (
    <>
      <RatingView
        stars={props.stars}
        ratingValue={props.ratingValue}
        fillColor={props.fillColor}
        size={props.size}
      />
    </>
  );
};

Star.propTypes = propTypes;
Star.defaultProps = defaultProps;

export default Star;

// how to use
// Ex1: <Star />
// Ex2: <Star stars={5} ratingValue={2} fillColor="#f1a545" />
