// extracted by mini-css-extract-plugin
export var regForm_ctaSec = "RegisterForm-module--regForm_ctaSec--HIAg2";
export var regForm_ctaSec_btn = "RegisterForm-module--regForm_ctaSec_btn--aKV82";
export var regForm_ctaSec_icnSec = "RegisterForm-module--regForm_ctaSec_icnSec--r66pb";
export var regForm_desktpLine = "RegisterForm-module--regForm_desktpLine--hYVPI";
export var regForm_formInput_sec = "RegisterForm-module--regForm_formInput_sec--mMzKw";
export var regForm_formSec = "RegisterForm-module--regForm_formSec--IelJs";
export var regForm_hedngSec = "RegisterForm-module--regForm_hedngSec--+1KcV";
export var regForm_mobLine = "RegisterForm-module--regForm_mobLine--3UDBj";
export var regForm_sec = "RegisterForm-module--regForm_sec--6IrPk";
export var registerForm_modalDesk_crossIcn = "RegisterForm-module--registerForm_modalDesk_crossIcn--WyKL8";
export var registerForm_modalMob_crossIcn = "RegisterForm-module--registerForm_modalMob_crossIcn--WD8k9";
export var registerForm_modalMob_header = "RegisterForm-module--registerForm_modalMob_header--4sCfX";