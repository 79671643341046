import React from "react";

// components
import Carousel from "./Carousel";

//styles
import * as styles from "./SuccessStories.module.css";

const SuccessStories = props => {
  return (
    <div className={props.bgColor ? props.bgColor : "bgLightgray"}>
      <div className={`container twinContainer ${styles.marginCo}`}>
        <div className="row ">
          {props.titleText ? (
            <div className="col-12 pt-4 pb-3">
              <div className="col-md-6 col-11">
                <p className={styles.secondaryTxt}>{props.titleText}</p>
              </div>
            </div>
          ) : (
            <div className="col-12 pb-3">
              <p className="gradientText">Customer Success Stories</p>
            </div>
          )}

          <div className="col-12">
            <hr className={`${styles.darkHr}`} />
          </div>
          <div className="col-12 col-md-12 col-lg-12">
            <Carousel />
          </div>
          <div className="col-12 d-none d-md-block d-lg-block">
            <hr className={`${styles.darkHr}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStories;
